import React from 'react'
import Result from './Result.js'
import Spinner from './Spinner.js'
import { MathComponent } from 'mathjax-react'
import ScatterChart from './ScatterChart.js'
import coinImages from '../coinImages.js'
import github from '../GitHub-Mark-64px.png'

class PortfolioResults extends React.Component {
	render () {
		if (this.props.loading==="none") {
			return(<div></div>)
		} else if (this.props.loading==="loading") {
			return(<Spinner />)
		} else {
			console.log('results:', this.props.results)
			var significantResults = []
			for (var i = 0; i < this.props.results.allocation.length; i++) {
				var allocation = this.props.results.allocation[i]
				if (allocation.allocation > 0.0005) {significantResults.push(allocation)}
			}
			const lines = significantResults.map(item => {
				return(
					<Result
						id={item.coin}
						allocation={item.allocation}
						meanReturn={item.mean}
						risk={item.std}
					/>
				)
			})
			var optimal_return = Number(this.props.results.optimal_return.substring(0, 
				this.props.results.optimal_return.length - 1))
			var optimal_risk = Number(this.props.results.optimal_risk.substring(0, 
				this.props.results.optimal_risk.length - 1))
			return (
			<div>
				<div className="mb-8">
					<div className="mb-8">
						<h1 className="text-indigo-500 text-lg font-semibold">Your Crypto Portfolio</h1>
						<div className="my-8 flex flex-col md:flex-row justify-around">
							<div className="mb-8 md:mb-0 flex flex-col items-center">
								<p className="leading-tight tracking widest uppercase font-thin text-indigo-500">Risk Profile</p>
								<p className="text-4xl font-semibold text-gray-100">{this.props.results.tolerance}</p>
							</div>
							<div className="flex flex-col items-center">
								<p className="leading-tight tracking widest uppercase font-thin text-indigo-500">Expected daily return</p>
								<p className="text-4xl font-semibold text-gray-100">
								{Math.round(optimal_return * 1000) / 1000}
								<span className="text-2l font-medium text-gray-200">%</span>
								<span className=" text-2xl text-gray-300"> &plusmn; {Math.round(optimal_risk * 1000) / 1000}%</span>
								</p>
							</div>
							
						</div>
						<p className="text-gray-300">Here is a list of the cryptocurrencies you should invest in according to your risk level. The allocation level represents the proportion of your portfolio you should invest in each coin, according to MPT.</p>
					</div>
					<div className="flex ml-14">
						<p className="w-1/3 float-left text-indigo-500 text-lg font-semibold">Coin</p>
						<p className="w-1/3 float-left text-indigo-500 text-lg font-semibold">Allocation</p>
						<p className="w-1/3 float-left text-indigo-500 text-lg font-semibold">Return &plusmn; risk</p>
					</div>
					{lines}
				</div>
				<div className="mb-8">
					<h1 className="text-indigo-500 text-lg font-semibold">Why did you get this result?</h1>
					<p className="text-gray-300">Let's look at how MPT works in more detail. First, we define a formula for the value of a portfolio that looks like:</p> <div className="flex w-full justify-center text-gray-200"><MathComponent tex={String.raw`V = R - \alpha S`} /></div>
					<p className="text-gray-300">where R is the return and S is the risk. The parameter represents your risk tolerance. </p>
					
					<p className="text-gray-300">Next, we want to find the returns for each coin. We can think of the prices of the coin on each day as a list of datapoints, each giving evidence about how well a coin performs. Notice that there's an assumption here that past price movements predict future price movements. We calculate the returns for each day by looking at the price of each coin over the past 2 years, then computing the logarithm of the daily increase. Given the price p in USD on day i, the log-return is given by: </p> <div className="flex w-full justify-center text-gray-200"><MathComponent tex={String.raw`r_i = log\left(\frac{p_{i+1}}{p_i}\right)`} /></div>
					
					<p className="text-gray-300">Let's say we represent out portfolio allocation by a vector w, where each elements represents the proportion of our portfolio invested in each coin. Then the return of a portfolio is given by the scalar product of w with a vector of the mean returns for each coin:</p> <div className="flex w-full justify-center text-gray-200"><MathComponent tex={String.raw`R = \bf{w}^T \bf{\bar{r}}`} /></div>
					<p className="text-gray-300">Risk is a bit more difficult. We have to take into consideration the fact that the price of different coins isn't independent, so we can't consider them in isolation. Think about this: when the price of Bitcoin goes up, other coins tend to follow. Cryptocurrencies tend to be highly correlated. In order to find how similar these price movements are, we analyse the historical data, and summarise this in a covariance matrix M. The entries of this matrix represent the covariance, a measure of similarity, between each different coin. We then calculate the overall risk as as:</p><div className="flex w-full justify-center text-gray-200"><MathComponent tex={String.raw`S = \bf{w}^T \bf{M} \bf{w}`} /></div>
					<p className="text-gray-300">So the overall value of a portfolio with allocation w is:</p><div className="flex w-full justify-center text-gray-200"><MathComponent tex={String.raw`V = \bf{w}^T \bf{\bar{r}} - \alpha \bf{w}^T \bf{M} \bf{w}`} /></div>
					<p className="text-gray-300">We then simply use an optimisation algorithm to maximise this value, subject to the constraint that the allocation must add up to 100%. That gives us your optimal portfolio.</p>
				</div>
				<ScatterChart
					data={this.props.results.allocation}
					log_mean={this.props.results.optimal_log_return}
					log_std={this.props.results.optimal_log_risk}
					mean={this.props.results.optimal_return}
					std={this.props.results.optimal_risk}
				/>
				<div className="mb-8">
					<h1 className="text-indigo-500 text-lg font-semibold">Things to consider when using MPT to inform your investing strategy.</h1>
					<ul className="text-gray-300 list-disc pl-4">
						<li>This implementation uses data from the past 2 years to assess the returns. This is essentially assuming that performance over the past 2 years will predict future performance. There might be some truth in that.</li>
						<li>There are probably other ways you could assess the returns.</li>
						<li>MPT makes the assumption that variance is a suitable proxy for risk; that is, that investors should care only about fluctuations in the price of cryptocurrencies when thinking about risk. Many people interested in cryptocurrencies are value investors.</li>
						<li>MPT assumes that the returns follow a Normal Distribution. Cryptocurrencies tend to be highly volatile.</li>
						<li>The most important thing to remember is that it is always a good idea to diversify. You get less risk, for free.</li>
					</ul>
				</div>
				<div className="mt-8 flex justify-center">
					<a className="text-gray-100 hover:text-gray-300 flex flex-col items-center" href="https://github.com/maxsmartofficial/crypto_mpt_api">
						<img className="w-16 h-16" src={github} />
						<p>Crypto MPT API</p>
					</a>
				</div>
			</div>
			)
		}
	}
}

export default PortfolioResults