export default {
	"bitcoin": {
		"name": "Bitcoin",
		"thumb": "https://assets.coingecko.com/coins/images/1/thumb/bitcoin.png?1547033579",
		"small": "https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579",
		"large": "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579"
	},
	"maker": {
		"name": "Maker",
		"thumb": "https://assets.coingecko.com/coins/images/1364/thumb/Mark_Maker.png?1585191826",
		"small": "https://assets.coingecko.com/coins/images/1364/small/Mark_Maker.png?1585191826",
		"large": "https://assets.coingecko.com/coins/images/1364/large/Mark_Maker.png?1585191826"
	},
	
	"ftx-token": {
		"name": "FTX Token",
		"thumb": "https://assets.coingecko.com/coins/images/9026/thumb/F.png?1609051564",
		"small": "https://assets.coingecko.com/coins/images/9026/small/F.png?1609051564",
		"large": "https://assets.coingecko.com/coins/images/9026/large/F.png?1609051564"
	},
	"theta-token": {
		"name": "Theta Network",
		"thumb": "https://assets.coingecko.com/coins/images/2538/thumb/theta-token-logo.png?1548387191",
		"small": "https://assets.coingecko.com/coins/images/2538/small/theta-token-logo.png?1548387191",
		"large": "https://assets.coingecko.com/coins/images/2538/large/theta-token-logo.png?1548387191"
	},
	"matic-network": {
		"name": "Polygon",
		"thumb": "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912",
		"small": "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
		"large": "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912"
	},
	"bitcoin-cash": {
		"name": "Bitcoin Cash",
		"thumb": "https://assets.coingecko.com/coins/images/780/thumb/bitcoin-cash-circle.png?1594689492",
		"small": "https://assets.coingecko.com/coins/images/780/small/bitcoin-cash-circle.png?1594689492",
		"large": "https://assets.coingecko.com/coins/images/780/large/bitcoin-cash-circle.png?1594689492"
	},
	"tezos": {
		"name": "Tezos",
		"thumb": "https://assets.coingecko.com/coins/images/976/thumb/Tezos-logo.png?1547034862",
		"small": "https://assets.coingecko.com/coins/images/976/small/Tezos-logo.png?1547034862",
		"large": "https://assets.coingecko.com/coins/images/976/large/Tezos-logo.png?1547034862"
	},
	"bitcoin-cash-sv": {
		"name": "Bitcoin SV",
		"thumb": "https://assets.coingecko.com/coins/images/6799/thumb/BSV.png?1558947902",
		"small": "https://assets.coingecko.com/coins/images/6799/small/BSV.png?1558947902",
		"large": "https://assets.coingecko.com/coins/images/6799/large/BSV.png?1558947902"
	},
	"vechain": {
		"name": "VeChain",
		"thumb": "https://assets.coingecko.com/coins/images/1167/thumb/VeChain-Logo-768x725.png?1547035194",
		"small": "https://assets.coingecko.com/coins/images/1167/small/VeChain-Logo-768x725.png?1547035194",
		"large": "https://assets.coingecko.com/coins/images/1167/large/VeChain-Logo-768x725.png?1547035194"
	},
	"tether": {
		"name": "Tether",
		"thumb": "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
		"small": "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707",
		"large": "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707"
	},
	"okb": {
		"name": "OKB",
		"thumb": "https://assets.coingecko.com/coins/images/4463/thumb/okb_token.png?1548386209",
		"small": "https://assets.coingecko.com/coins/images/4463/small/okb_token.png?1548386209",
		"large": "https://assets.coingecko.com/coins/images/4463/large/okb_token.png?1548386209"
	},
	"ethereum-classic": {
		"name": "Ethereum Classic",
		"thumb": "https://assets.coingecko.com/coins/images/453/thumb/ethereum-classic-logo.png?1547034169",
		"small": "https://assets.coingecko.com/coins/images/453/small/ethereum-classic-logo.png?1547034169",
		"large": "https://assets.coingecko.com/coins/images/453/large/ethereum-classic-logo.png?1547034169"
	},
	"terra-luna": {
		"name": "Terra",
		"thumb": "https://assets.coingecko.com/coins/images/8284/thumb/luna1557227471663.png?1567147072",
		"small": "https://assets.coingecko.com/coins/images/8284/small/luna1557227471663.png?1567147072",
		"large": "https://assets.coingecko.com/coins/images/8284/large/luna1557227471663.png?1567147072"
	},
	"chainlink": {
		"name": "Chainlink",
		"thumb": "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
		"small": "https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png?1547034700",
		"large": "https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700"
	},
	"tron": {
		"name": "TRON",
		"thumb": "https://assets.coingecko.com/coins/images/1094/thumb/tron-logo.png?1547035066",
		"small": "https://assets.coingecko.com/coins/images/1094/small/tron-logo.png?1547035066",
		"large": "https://assets.coingecko.com/coins/images/1094/large/tron-logo.png?1547035066"
	},
	"cosmos": {
		"name": "Cosmos",
		"thumb": "https://assets.coingecko.com/coins/images/1481/thumb/cosmos_hub.png?1555657960",
		"small": "https://assets.coingecko.com/coins/images/1481/small/cosmos_hub.png?1555657960",
		"large": "https://assets.coingecko.com/coins/images/1481/large/cosmos_hub.png?1555657960"
	},
	"neo": {
		"name": "NEO",
		"thumb": "https://assets.coingecko.com/coins/images/480/thumb/NEO_512_512.png?1594357361",
		"small": "https://assets.coingecko.com/coins/images/480/small/NEO_512_512.png?1594357361",
		"large": "https://assets.coingecko.com/coins/images/480/large/NEO_512_512.png?1594357361"
	},
	"stellar": {
		"name": "Stellar",
		"thumb": "https://assets.coingecko.com/coins/images/100/thumb/Stellar_symbol_black_RGB.png?1552356157",
		"small": "https://assets.coingecko.com/coins/images/100/small/Stellar_symbol_black_RGB.png?1552356157",
		"large": "https://assets.coingecko.com/coins/images/100/large/Stellar_symbol_black_RGB.png?1552356157"
	},
	"binancecoin": {
		"name": "Binance Coin",
		"thumb": "https://assets.coingecko.com/coins/images/825/thumb/binance-coin-logo.png?1547034615",
		"small": "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png?1547034615",
		"large": "https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png?1547034615"
	},
	"monero": {
		"name": "Monero",
		"thumb": "https://assets.coingecko.com/coins/images/69/thumb/monero_logo.png?1547033729",
		"small": "https://assets.coingecko.com/coins/images/69/small/monero_logo.png?1547033729",
		"large": "https://assets.coingecko.com/coins/images/69/large/monero_logo.png?1547033729"
	},
	"cardano": {
		"name": "Cardano",
		"thumb": "https://assets.coingecko.com/coins/images/975/thumb/cardano.png?1547034860",
		"small": "https://assets.coingecko.com/coins/images/975/small/cardano.png?1547034860",
		"large": "https://assets.coingecko.com/coins/images/975/large/cardano.png?1547034860"
	},
	"ethereum": {
		"name": "Ethereum",
		"thumb": "https://assets.coingecko.com/coins/images/279/thumb/ethereum.png?1595348880",
		"small": "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
		"large": "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880"
	},
	"litecoin": {
		"name": "Litecoin",
		"thumb": "https://assets.coingecko.com/coins/images/2/thumb/litecoin.png?1547033580",
		"small": "https://assets.coingecko.com/coins/images/2/small/litecoin.png?1547033580",
		"large": "https://assets.coingecko.com/coins/images/2/large/litecoin.png?1547033580"
	},
	"dogecoin": {
		"name": "Dogecoin",
		"thumb": "https://assets.coingecko.com/coins/images/5/thumb/dogecoin.png?1547792256",
		"small": "https://assets.coingecko.com/coins/images/5/small/dogecoin.png?1547792256",
		"large": "https://assets.coingecko.com/coins/images/5/large/dogecoin.png?1547792256"
	},
	"ripple": {
		"name": "XRP",
		"thumb": "https://assets.coingecko.com/coins/images/44/thumb/xrp-symbol-white-128.png?1605778731",
		"small": "https://assets.coingecko.com/coins/images/44/small/xrp-symbol-white-128.png?1605778731",
		"large": "https://assets.coingecko.com/coins/images/44/large/xrp-symbol-white-128.png?1605778731"
	}
}