import logo from './logo.svg';
import React from 'react'
import axios from 'axios'
import coinImages from './coinImages.js'
import Intro from './components/Intro.js'
import RiskQuiz from './components/RiskQuiz.js'
import PortfolioResults from './components/PortfolioResults.js'

import quizChoices from './quizChoices.js'

class App extends React.Component {
	constructor () {
		super()
		this.state = {
			results_loading: "none" // "none", "loading", "done"
		}
		this.fetchResults = this.fetchResults.bind(this)
		this.quizHandle = this.quizHandle.bind(this)
	}
	
	fetchResults (parameters) {
		this.setState({results_loading: "loading"})
		axios.get('https://supercryptobots.com/crypto_mpt_api/v1.0/', {params: parameters}).then(response => {
			this.setState({results: response.data})
			this.setState({results_loading: 'done'})
			console.log(this.state)
		})
	}
	
	quizHandle(results) {
		this.fetchResults(results)
	}
	
	render () {

		return (
			<div className=" border-t-4 border-indigo-500 min-h-screen bg-gray-800 flex flex-col">
				<div className="flex items-center justify-center">
					<div className="max-w-4xl sm:m-4 sm:rounded-lg bg-gray-700 px-4 py-8 w-full">
						<Intro />
						<RiskQuiz choices={quizChoices} handle={this.quizHandle} />
						<PortfolioResults loading={this.state.results_loading} results={this.state.results} />
					</div>
				</div>
			</div>
			
			

		)
	}
}



export default App;
