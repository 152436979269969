import React from 'react'
import coinImages from '../coinImages.js'
import axios from 'axios'

class Result extends React.Component {
	
	constructor () {
		super()
		this.state={coinData:{}}
	}
	
	componentDidMount () {
		var params = {
			ids: this.props.id,
			vs_currencies: 'usd',
			include_24hr_change: true
		}
		axios.get('https://api.coingecko.com/api/v3/simple/price', {params: params}).then(response => {
				this.setState({coinData: response.data[this.props.id]})
			})
	}
	
	render () {
		console.log(this.props, this.state)
	return(
		<div className="my-2 p-1 rounded-sm flex items-center w-full bg-gray-600">
			<a href={"https://www.coingecko.com/en/coins/" + this.props.id} className="hover:scale-110 transform transition"><img className="w-8 h-8 m-1 mr-4" src={coinImages[this.props.id]["small"]} /></a>
			<div className="flex justify-between w-full items-center">
				<div className="w-1/3 float-left">
					<h1 className=" text-sm md:text-base text-gray-100 font-semibold">{coinImages[this.props.id]["name"]}</h1>
					<p>
						<span className="text-sm md:text-lg font-semibold text-gray-100">${this.state.coinData.usd}</span>
						<span 
							className={"text-xs md:text-sm " + (this.state.coinData.usd_24h_change > 0 ? 'text-green-500' : 'text-red-500')}
							>
							&nbsp;{Math.round(this.state.coinData.usd_24h_change * 10)/10}%
						</span>
					</p>
				</div>
				
				<div className="w-1/3 float-left">
					<p className="text-2xl md:text-4xl font-semibold text-gray-100">
						{Math.round(this.props.allocation * 1000)/10}
						<span className="text-base md:text-xl font-medium text-gray-200">%</span>
					</p>
				</div>
				
				<div className="w-1/3 float-left">
					<p>
						<span className="text-base md:text-lg font-semibold text-gray-100">{this.props.meanReturn}</span>
						<span className="text-sm md:text-base text-gray-200"> &plusmn; {this.props.risk}</span>
					</p>
				</div>
			</div>
		</div>
	)
	}
}

export default Result