import React from 'react'
import QuizChoice from './QuizChoice.js'

class QuizQuestion extends React.Component {
	constructor () {
		super()
		this.questionHandle = this.questionHandle.bind(this)
		this.checkHandle = this.checkHandle.bind(this)
	}
	
	
	questionHandle (value) {
		this.props.handle(this.props.name, value)
	}
	
	checkHandle (value) {
		return (this.props.value === value)
	}
	
	render () {
		const choices = this.props.choices.map(item => {
			return(

					<QuizChoice
						key={item.id}
						value={item.value}
						text={item.text}
						subtext={item.subtext}
						handle={this.questionHandle}
						checkHandle={this.checkHandle}
					/>

			)
		})
		return(
			<div className="flex flex-col mb-8">
				<p className="text-indigo-500 text-lg font-semibold mb-2" >{this.props.text}</p>
				{choices}
				<p className="text-red-300">{this.props.validation}</p>
					
			</div>
		)
	}
}

export default QuizQuestion