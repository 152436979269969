import React, { Component } from 'react';
import Chart from 'chart.js/auto';
import coinImages from '../coinImages.js'

export default class ScatterChart extends Component {
	constructor () {
		super()
		this.state={}
	}

	chartRef = React.createRef();

	componentDidMount() {
		const ctx = this.chartRef.current.getContext("2d");
		const coin_data = this.props.data.map(item => {
			return({y: item.log_mean, x: item.log_std, name:coinImages[item.coin]["name"], mean:item.mean, std:item.std})
		})
		console.log('coin_data:',coin_data)
		const coin_images = this.props.data.map( item => {
			var coin_image = new Image(20, 20)
			coin_image.src = coinImages[item.coin]["thumb"]
			return(coin_image)
		})
		coin_data.push({
			y:this.props.log_mean,
			x:this.props.log_std,
			name: "Optimal Portfolio",
			mean: this.props.mean,
			std: this.props.std
		})
		coin_images.push("circle")
		new Chart(ctx, {
			type: 'scatter',
			data: {
  datasets: [{
    label: 'Scatter Dataset',
	pointStyle: coin_images,
    data: coin_data,
    backgroundColor: 'rgb(255, 99, 132)'
  }],
},
			options: {
				
				scales: {
					x: {
						ticks: {
							display: false
						},
						type: 'linear',
						position: 'bottom',
						title: {
							display: true,
							color: "rgba(99, 102, 241)",
							text:"Risk"
						}
					},
					y: {
						ticks: {
							display: false
						},
						type: 'linear',
						position: 'left',
						title: {
							display: true,
							color: "rgba(99, 102, 241)",
							text:"Return"
						}
					}
				},
				plugins: {
					title: {
						display: true,
						font: {
							weight: 'bold',
							size: 16
						},
						color: "rgba(99, 102, 241)",
						text:"Return and Risk"
					},
					legend: {
						display: false
					},
					tooltip: {
						displayColors: false,
						callbacks: {
							title: function(context) {
								var name = String(context[0].raw.name)
								return(name)
							},
							label : function(context) {
								var mean = context.raw.mean
								var std = context.raw.std
								return(mean + " +/- " + std)
							}
						}
					}
				}
			}
		});
	}
	
	render() {
		return (
			<div className="mb-8">
				<canvas
				id="myChart"
				ref={this.chartRef}
				/>
			</div>
			)
	}
}