function choiceValidation(choices) {
	return((value) => {

			var inChoices = "You must select an option"
			for (var i = 0; i < choices.length; i++) {
				if (choices[i].value===value) {
					inChoices = null
				}
			}
			return(inChoices)
	})
}

var risk_level_choices = [
	{
		id: 1,
		value: 1,
		text: "Very high",
		subtext: "I am want to pursue maximum profits, regardless of the risks."
	},
	{
		id: 2,
		value: 2,
		text: "High",
		subtext: "I want to prioritise high returns."
	},
	{
		id: 3,
		value: 3,
		text: "Average",
		subtext: "I want to construct a balanced cryptocurrency portfolio that achieves reasonable returns."
	},
	{
		id: 4,
		value: 4,
		text: "Low",
		subtext: "I want to prioritise stability."
	},
	{
		id: 5,
		value: 5,
		text: "Very Low",
		subtext: "I want to invest in cryptocurrencies without overexposing myself to risk."
	}
]


var asset_questions = [
	{
		id: 1,
		value: 4,
		text: "A tiny amount",
		subtext: "Less than 0.1% of your total assets."
	},
	{
		id: 2,
		value: 3,
		text: "A small amount",
		subtext: "0.1-0.5% of your total assets."
	},
	{
		id: 3,
		value: 2,
		text: "A significant amount",
		subtext: "0.5-2% of your total assets."
	},
	{
		id: 4,
		value: 1,
		text: "A large amount",
		subtext: "More than 2% of your total assets."
	}
]

// Are you relying on your cryptocurrency portfolio to hold it's value
var value_questions = [
	{
		id: 1,
		value: 1,
		text: "Yes",
		subtext: "I'm not happy with the idea of losing money."
	},
	{
		id: 2,
		value: 2,
		text: "Somewhat",
		subtext: "I don't mind taking a few risks, so long as they are controlled."
	},
	{
		id: 3,
		value: 3,
		text: "No",
		subtext: "I'm not counting on cryptocurrencies to be a store of value."
	}
]

// How stable are your non-cryptocurrency sources of income?
var funding_questions = [
	{
		id: 1,
		value: 1,
		text: "Unstable",
		subtext: "My income tends to be highly variable."
	},
	{
		id: 2,
		value: 2,
		text: "Adequately stable",
		subtext: "I can rely on other sources of income, but I'm worried about it's future stability."
	},
	{
		id: 3,
		value: 3,
		text: "Highly stable",
		subtext: "I cannot forsee any problems with my income."
	},
]

export default {
	return_function: function (params) {
		var total = params.assets + params.value + params.funding
		if (total == 3) { var tolerance = 5 }
		else if (total == 4 || total == 5) { var tolerance = 4 }
		else if (total == 6 || total == 7) { var tolerance = 3 }
		else if (total == 8 || total == 9) { var tolerance = 2 }
		else if (total == 10) { var tolerance = 1 }
		return (
		{
			
			tolerance: tolerance
		}
		)
	},
	questions: [
	{
		id: 1,
		name: "assets",
		text: "What percentage of your total assets do you want to invest in cryptocurrencies?",
		choices: asset_questions,
		validation: choiceValidation(asset_questions)
	},
	{
		id: 2,
		name: "value",
		text: "Are you relying on your cryptocurrency portfolio to hold it's value?",
		choices: value_questions,
		validation: choiceValidation(value_questions)
	},
	{
		id: 3,
		name: "funding",
		text: "How stable are your non-cryptocurrency sources of income?",
		choices: funding_questions,
		validation: choiceValidation(funding_questions)
	}
	]
}