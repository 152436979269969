import React from 'react'

class QuizChoice extends React.Component {
	constructor () {
		super()
		this.handleClick = this.handleClick.bind(this)
		this.isChecked = this.isChecked.bind(this)
	}
	
	handleClick () {
		this.props.handle(this.props.value)
	}
	
	isChecked () {
		if (this.props.checkHandle(this.props.value)) {
			return(true)
		} else {
			return(false)
		}
	}
	
	render () {
		return (
			<div className="my-1 flex">
				<label className={`w-full p-2 rounded-sm cursor-pointer bg-gray-600 border-indigo-600 hover:shadow ${this.isChecked() ? "shadow-none hover:shadow-none border-l-4" : "shadow-lg"}`}>
					<input
						type="radio"
						className="hidden"
						value={this.props.value}
						onChange={this.handleClick}
						checked={this.isChecked()}
					/>
					<div className="flex items-center">
						<div className="w-6 h-6 mr-2">
							<svg xmlns="http://www.w3.org/2000/svg" className={`w-6 h-6 text-indigo-500 ${this.isChecked() ? 'visible' : 'invisible'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
						</svg>
						</div>
						<div>
							<p className="text-lg text-gray-100 leading-tight">{this.props.text}</p>
							<p className="text-sm text-gray-300 leading-tight">{this.props.subtext}</p>
						</div>
					</div>

				</label>
			</div>
		)
	}
}

export default QuizChoice