
import React from 'react'

export default () => {
	return(
	<div className="mb-8">
		<div className="mb-8">
			<h1 className="text-indigo-500 text-2xl">Crypto Portfolio Theory</h1>
			<p className="text-gray-300 text-lg">Find your optimal cryptocurrency portfolio using Modern Portfolio Theory.</p>
		</div>
		<div className="mb-8">
			<h1 className="text-indigo-500 text-lg font-semibold">What is Modern Portfolio Theory?</h1>
			<p className="text-gray-300">Modern Portfolio Theory (MPT) is a technique for optimising a portfolio of assets while maximising return and minimising risk. Investing in cryptocurrencies is risky, so MPT can be a great way to make sure you don't overinvest in one coin. First, you find your risk tolerance, then MPT uses that to personalise a diversified portfolio for you.</p>
		</div>
	</div>
	)
}