import React from 'react'
import QuizQuestion from './QuizQuestion.js'


class RiskQuiz extends React.Component {
	constructor () {
		super()
		this.state = {
			answers: {},
			validation: {}
		}
		this.questionHandle = this.questionHandle.bind(this)
		this.submitQuiz = this.submitQuiz.bind(this)
		this.validate = this.validate.bind(this)
	}
	
	
	componentDidMount () {
		var validation_object = {}
		for (var i = 0; i < this.props.choices.questions.length; i++) {
			var question = this.props.choices.questions[i]
			validation_object[question.name] = null
		}
		this.setState({validation: validation_object})
	}
	
	validate () {
		var validation_object = {}
		var validated = true
		for (var i = 0; i < this.props.choices.questions.length; i++) {
			var question = this.props.choices.questions[i]
			var value = this.state.answers[question.name]
			var validation_value = question.validation(value)
			validation_object[question.name] = validation_value
			if (validation_value !== null) {validated = false}
		}
		this.setState({validation: validation_object})
		return(validated)
	}
	
	submitQuiz (event) {
		console.log('submitiing')
		event.preventDefault()
		// Perform validation
		var validated = this.validate()
		console.log('valid?:',validated)
		if (validated) {
			// Transform this.answers according to return_function
			var results = this.props.choices.return_function(this.state.answers)
			// Submit the results to the given handle
			this.props.handle(results)
		}
	}
	
	questionHandle (questionName, answer) {
		// Change the current answer to a given question
		this.setState(prevState => {
			return({answers: {...prevState.answers, [questionName]: answer}})
		})
	}
	
	render () {
		
		const questions = this.props.choices.questions.map(item => {
			const value = this.state.answers[item.name]
			const validation = this.state.validation[item.name]
			return(
					<QuizQuestion
						key={item.id}
						name={item.name}
						text={item.text}
						choices={item.choices}
						value={value}
						handle={this.questionHandle}
						validation={validation}
					/>
			)
		})
		return(
			<div className="mb-8">
				<div className="mb-8">
					<h1 className="text-indigo-500 text-lg font-semibold">What is your risk level?</h1>
					<p className="text-gray-300">Your risk tolerance describes how reliable you want your portfolio to be. A stable portfolio is perfect for people looking to minimise their exposure to risk. An aggressive portfolio strategy involves taking more chances to pursue profit at all costs. Answer some questions to find your risk profile.</p>
				</div>
				<form onSubmit={this.submitQuiz}>
					{questions}
					<button className="border-2 rounded-sm shadow-lg hover:shadow bg-gray-600 hover:text-indigo-200 border-gray-700 px-10 py-2 text-lg font-semibold text-gray-100 focus:outline-none focus:border-gray-700 focus:text-indigo-200 focus:shadow-none">Submit</button>
				</form>
			</div>
		)
	}
}

export default RiskQuiz